import React from 'react';
import CurrencyInput from "./CurrencyInput";

declare var OrbipayCheckoutSimple: any;

interface IProps {
    firstName: string,
    middleName: string,
    lastName: string,
    suffixName: string,
    id: string,
    docNum: string,
    billerId: string,
    turl: string,
    surl: string,
    bsn: string,
    clientKey: string,
    paymentMax: number,
    paymentMin: number,
}

interface IState {
    firstName: string,
    middleName: string,
    lastName: string,
    suffixName: string,
    id: string,
    docNum: string,
    billerId: string,
    turl: string,
    surl: string,
    bsn: string,
    clientKey: string,
    paymentMax: number,
    paymentMin: number,
    value: string,
    floatValue: number,
    error: string,
    token: string,
    digiSign: string,
    customerAccountReference: string,
    canMakePayment: boolean,
    result: any,
    tempTurl: string,
    target: string,
}

export default class PaymentForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            firstName: props.firstName,
            middleName: props.middleName,
            lastName: props.lastName,
            suffixName: props.suffixName,
            id: props.id,
            docNum: props.docNum,
            billerId: props.billerId,
            turl: props.turl,
            surl: props.surl,
            bsn: props.bsn,
            clientKey: props.clientKey,
            paymentMax: props.paymentMax,
            paymentMin: props.paymentMin,
            value: '',
            floatValue: 0,
            error: '',
            token: '',
            digiSign: '',
            customerAccountReference: props.bsn.substr(6,3) + props.id,
            result: null,
            canMakePayment: false,
            tempTurl: props.turl,
            target: 'receiver',
        };

        this.validateRange = this.validateRange.bind(this);
    }

    componentDidMount() {
        OrbipayCheckoutSimple.setClientKey(this.state.clientKey);
        OrbipayCheckoutSimple.setApiEvent("create_payment");
        OrbipayCheckoutSimple.setCustomerAccountReference(this.state.bsn.substr(6,3) + this.state.id);

        OrbipayCheckoutSimple.setCustomFields({
            "cdf001": this.state.docNum,
            "cdf002": this.state.firstName,
            "cdf003": this.state.lastName,
            "cdf004": this.state.middleName || "",
            "cdf005": this.state.suffixName || "",
            "cdf006": this.state.billerId.replace("RES", "COF").substr(0, 1),
            "cdf007": this.state.id
        });

        let turl = encodeURI(this.state.tempTurl +
                '?a=' + this.state.docNum +
                '&b=' + this.state.firstName +
                '&c=' + this.state.lastName +
                '&d=' + (this.state.middleName || "") +
                '&e=' + (this.state.suffixName || "") +
                '&f=' + this.state.billerId.replace("RES", "COF").substr(0, 1) +
                '&g=' + this.state.id);

        this.setState({tempTurl: turl});

        if (this.state.bsn.substr(6,3).toUpperCase() === 'ICA' && this.state.paymentMin > 0) {
            console.log("componentDidMount: " + this.state.paymentMin.toFixed(2));
            OrbipayCheckoutSimple.setAmount(this.state.paymentMin.toFixed(2));
            turl = turl + "&amount=" + this.state.paymentMin.toFixed(2).replace(".", "");
            this.setState({canMakePayment: true});
            this.setState({value: '$' + this.state.paymentMin.toFixed(2)});
        }

        this.setState({turl: turl}, () => console.log("turl: " + this.state.turl));
    }

    validateRange(e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: string };
        if(target.value.length > 0) {
            this.setState({value: target.value});
            this.setState({floatValue: parseFloat(target.value.replace("$", ""))}, () => {
                if (this.state.floatValue >= this.state.paymentMin && this.state.floatValue <= this.state.paymentMax) {
                    this.setState({error: ""});
                    this.setState({canMakePayment: true});
                    this.setState({turl: this.state.tempTurl + "&amount=" + this.state.floatValue.toFixed(2).replace(".", "")},
                        () => console.log("onChange new turl: " + this.state.turl));
                    OrbipayCheckoutSimple.setAmount(this.state.floatValue.toFixed(2));
                } else {
                    this.setState({error: "Must be between $" + this.state.paymentMin.toFixed(2) + " and $" + this.state.paymentMax.toFixed(2)});
                    this.setState({canMakePayment: false});
                }
            });
        } else {
            this.setState({value: ""}, () => {console.log("Cleared payment amount");});
            this.setState({error: ""});
            this.setState({canMakePayment: false});
        }
    }

    render() {
        const {
            canMakePayment,
            value,
            error,
            target,
        } = this.state;

        return(
            <form id="orbipay-checkout-form"
                  onChange={(e: React.SyntheticEvent) => {
                      this.validateRange(e);
                  }}
                  onSubmit={(e: React.SyntheticEvent) => {
                      if(this.state.target==='receiver') {
                          e.preventDefault();
                          this.setState({target: '_self'});
                      }
                  }}
                  action={this.state.turl}
                  method="POST"
                  target={target}
            >
                <script id="orbipay-checkout-script"
                        src={this.state.surl}
                >
                </script>

                { this.state.bsn.substr(6,3).toUpperCase() === 'ICA' && this.state.paymentMin === this.state.paymentMax
                    ?  <CurrencyInput value={value} size="5" required disabled maskOptions/>
                    :  <CurrencyInput value={value} size="5" required maskOptions/>
                }

                { error.length > 0 &&
                    <div className={"pay-error"}>{error}</div>
                }

                { canMakePayment
                    ? <button id="orbipay-checkout-button" className="btn btn-primary">Make Payment</button>
                    : <button id="orbipay-checkout-button" className="btn btn-primary" disabled>Make Payment</button>
                }

                <iframe id="receiver" className="hidden" title="initialTarget"></iframe>
            </form>
        );
    }
}