import React, { MouseEventHandler } from 'react';

interface IProps {
    onClick: MouseEventHandler,
    className: string,
    children: string
}

interface IState {
    onClick: MouseEventHandler,
    className: string,
    children: string
}

export default class Button extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            onClick: props.onClick,
            className: props.className,
            children: props.children,
        };
    }

    render() {
        const {
            onClick,
            className,
            children,
        } = this.state;

        return(
            <button onClick={onClick}
                    className={className}
            >
                {children}
            </button>
        );
    }
}