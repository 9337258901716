import React from 'react';
import PaymentForm from "./PaymentForm";
import Button from "./Button";
import './App.css';

interface IProps {
    data: any,
    turl: string,
    surl: string,
    sfp: string,
    coo: string,
    gps: string,
    ica: string,
    max: string,
    min: string,
    cooKey: string,
    sfpKey: string,
    gpsKey: string,
    icaKey: string,
    searchAgain: any,
}

interface IState {
    data: any,
    turl: string,
    surl: string,
    bsn: string,
    key: string,
    sfp: string,
    coo: string,
    gps: string,
    ica: string,
    max: string,
    searchAgain: any,
    paymentType: string,
    restitutionMax: number,
    otherObligationsMax: number,
    icaDefault: number,
    icaAmount: number,
    owesRestitution: boolean,
    owesOther: boolean,
    owesICA: boolean,
    canPaySupvFee: boolean,
    paidSupvFee: string,
    pending: boolean,
    paymentMax: number,
    paymentMin: number,
    cooKey: string,
    sfpKey: string,
    gpsKey: string,
    icaKey: string,
}

export default class ResultTable extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            data: props.data,
            turl: props.turl,
            surl: props.surl,
            bsn: "",
            key: "",
            sfp: props.sfp,
            coo: props.coo,
            gps: props.gps,
            ica: props.ica,
            max: props.max,
            searchAgain: props.searchAgain,
            paymentType: "",
            restitutionMax: parseFloat(props.max),
            otherObligationsMax: parseFloat(props.max),
            icaDefault: 150.00,
            icaAmount: 0,
            owesRestitution: false,
            owesOther: false,
            owesICA: false,
            canPaySupvFee: true,
            paidSupvFee: "",
            pending: false,
            paymentMax: parseFloat(props.max),
            paymentMin: parseFloat(props.min),
            sfpKey: props.sfpKey,
            cooKey: props.cooKey,
            gpsKey: props.gpsKey,
            icaKey: props.icaKey,
        };

        this.payRestitution = this.payRestitution.bind(this);
        this.payCourtOrdered = this.payCourtOrdered.bind(this);
        this.paySupervision = this.paySupervision.bind(this);
        this.payGPS = this.payGPS.bind(this);
        this.payICA = this.payICA.bind(this);
        this.processResults = this.processResults.bind(this);
    }

    componentDidMount() {
        this.processResults(this.state.data);
    }

    processResults(data: any) {
        const numRestitution = parseFloat(data.restitution);
        const numSupvFee = parseFloat(data.supvFee);
        const numOther = parseFloat(data.otherObligations);
        const numGPS = parseFloat(data.gpsFee);
        const numICA = parseFloat(data.icaFee);
        const numPending = parseFloat(data.pending);

        if(numPending > 0) {
            data.restitution = 'Restitution Order is Pending';
            this.setState({pending: true});
            this.setState({owesRestitution: true});
        } else if(numRestitution <= 0) {
            data.restitution = '$0.00';
        } else if(numRestitution > this.state.paymentMax) {
            data.restitution = '$' + numRestitution.toFixed(2);
            this.setState({owesRestitution: true});
        } else {
            data.restitution = '$' + numRestitution.toFixed(2);
            this.setState({owesRestitution: true});
            this.setState({restitutionMax: numRestitution});
        }

        if(numSupvFee < 0) {
            this.setState({paidSupvFee: '$' + Math.abs(numSupvFee).toFixed(2) + ' Paid in Advance'});
            data.supvFee = 'Balance:  $0.00';
        } else if(numSupvFee === 0 && data.status === "Inactive") {
            this.setState({canPaySupvFee: false});
            data.supvFee = '$0.00';
        } else {
            data.supvFee = '$' + numSupvFee.toFixed(2);
        }

        if(numOther <= 0) {
            data.otherObligations = '$0.00';
        } else if(numOther > this.state.paymentMax) {
            data.otherObligations = '$' + numOther.toFixed(2);
            this.setState({owesOther: true});
        } else {
            data.otherObligations = '$' + numOther.toFixed(2);
            this.setState({owesOther: true});
            this.setState({otherObligationsMax: numOther});
        }

        if(numGPS <= 0) {
            data.gpsFee = '$0.00';
        } else {
            data.gpsFee = '$' + numGPS.toFixed(2);
        }

        if(numICA <= 0) {
            data.icaFee = '$0.00';
            this.setState({icaAmount: 0});
        } else {
            data.icaFee = '$' + numICA.toFixed(2);
            this.setState({icaAmount: numICA});
            this.setState({owesICA: true});
        }

    }

    payRestitution() {
        this.setState({paymentType: "RES"});
        this.setState({bsn: this.state.coo, key: this.state.cooKey});
        this.setState({paymentMax: this.state.restitutionMax});
    }

    payCourtOrdered() {
        this.setState({paymentType: "COF"});
        this.setState({bsn: this.state.coo, key: this.state.cooKey});
        this.setState({paymentMax: this.state.otherObligationsMax});
    }

    paySupervision() {
        this.setState({paymentType: "SVF"});
        this.setState({bsn: this.state.sfp, key: this.state.sfpKey});
        this.setState({paymentMax: parseFloat(this.state.max)});
    }

    payGPS() {
        this.setState({paymentType: "GPS"});
        this.setState({bsn: this.state.gps, key: this.state.gpsKey});
        this.setState({paymentMax: parseFloat(this.state.max)});
    }

    payICA() {
        this.setState({paymentType: "ICA"});
        this.setState({bsn: this.state.ica, key: this.state.icaKey});

        this.setState({paymentMax: this.state.icaAmount});

        if(this.state.icaDefault >= this.state.icaAmount) {
            this.setState({paymentMin: this.state.icaAmount});
        } else {
            this.setState({paymentMin: this.state.icaDefault});
        }
    }

    render() {
        const {
            data,
            turl,
            surl,
            bsn,
            key,
            searchAgain,
            paymentType,
            paymentMax,
            paymentMin,
            owesRestitution,
            owesOther,
            owesICA,
            canPaySupvFee,
            paidSupvFee,
            pending,
        } = this.state;

        return(
            <div>
                <div key="offender" className={"table"}>
                    <hr />
                    <dl>
                        <dt>DOC Number:</dt>
                        <dd>{data.docNum}</dd>
                    </dl>
                    <dl>
                        <dt>Alternate ID:</dt>
                        <dd>{data.offenderID}</dd>
                    </dl>
                    <dl>
                        <dt>Offender Name:</dt>
                        <dd>{data.firstName} {data.middleName} {data.lastName} {data.suffixName}</dd>
                    </dl>
                    <dl>
                        <dt>Offender Year of Birth:</dt>
                        <dd>{(new Date(data.dob)).getFullYear().toString()}</dd>
                    </dl>
                    <dl>
                        <dt>County of Supervision:</dt>
                        <dd>{data.countyOfSupervision}</dd>
                    </dl>
                    <dl>
                        <dt>Offender Status:</dt>
                        <dd>{data.status}</dd>
                    </dl>
                    <hr />
                    <div className="ObsTitle"><p>Obligation Totals:</p></div>
                    {(paymentType === "RES" || paymentType === "") &&
                    <dl>
                        <dt>Restitution:</dt>
                        <dd>{data.restitution}</dd>
                    </dl>
                    }
                    {(paymentType === "SVF" || paymentType === "") &&
                    <dl>
                        <dt>Supervision Fees:</dt>
                        <dd>{data.supvFee}<br />{paidSupvFee}</dd>
                    </dl>
                    }
                    {(paymentType === "COF" || paymentType === "") &&
                    <dl>
                        <dt>Other Court Ordered Obligations:</dt>
                        <dd>{data.otherObligations}</dd>
                    </dl>
                    }
                    {(paymentType === "GPS" || paymentType === "") &&
                    <dl>
                        <dt>GPS Fees:</dt>
                        <dd>{data.gpsFee}</dd>
                    </dl>
                    }
                    {(paymentType === "ICA" || paymentType === "") &&
                    <dl>
                        <dt>IC Application Fees:</dt>
                        <dd>{data.icaFee}</dd>
                    </dl>
                    }
                    { paymentType !== "" &&
                    <dl>
                        <dt>Payment Amount:</dt>
                        <dd>
                            <PaymentForm firstName={data.firstName}
                                         middleName={data.middleName}
                                         lastName={data.lastName}
                                         suffixName={data.suffixName}
                                         id={data.offenderID}
                                         docNum={data.docNum}
                                         billerId={paymentType}
                                         turl={turl}
                                         surl={surl}
                                         bsn={bsn}
                                         clientKey={key}
                                         paymentMax={paymentMax}
                                         paymentMin={paymentMin}

                            />
                        </dd>
                    </dl>
                    }
                    { data.status === "Active DAI" &&
                    <dl className={"red-background"}>This offender is currently incarcerated.<br />Please contact the institution for payment options.</dl>
                    }
                    { data.status !== "Active DAI" && pending &&
                    <dl className={"red-background"}>Restitution amount is still pending.<br />Please contact the supervising agent for payment options.</dl>
                    }
                    { data.status !== "Active DAI" && !pending &&
                    <dl className={"fine-print"}>Please contact the supervising agent with any questions.</dl>
                    }
                </div>
                <div key={data.docNum} className="btn-toolbar" role="toolbar">
                    <Button onClick={searchAgain} className={'btn btn-primary'}>New Search</Button>
                    { data.status !== "Active DAI" && !pending && owesRestitution && paymentType === "" &&
                    <Button onClick={this.payRestitution} className={'btn btn-primary'}>Pay Restitution</Button>
                    }
                    { data.status !== "Active DAI" && canPaySupvFee && !owesRestitution && paymentType === "" &&
                    <Button onClick={this.paySupervision} className={'btn btn-primary'}>Pay Supervision Fees</Button>
                    }
                    { data.status !== "Active DAI" && !owesRestitution && owesOther && paymentType === "" &&
                    <Button onClick={this.payCourtOrdered} className={'btn btn-primary'}>Pay Other Court Ordered Obligations</Button>
                    }
                    { data.status !== "Active DAI" && !owesRestitution && paymentType === "" &&
                    <Button onClick={this.payGPS} className={'btn btn-primary'}>Pay GPS Fees</Button>
                    }
                    { data.status !== "Active DAI" && !owesRestitution && owesICA && paymentType === "" &&
                    <Button onClick={this.payICA} className={'btn btn-primary'}>Pay IC Application Fees</Button>
                    }
                </div>
            </div>
        );
    }
}