import React from 'react';
import './App.css';

interface IProps {
    type: string,
    message: string,
}

interface IState {
    type: string,
    message: string,
}

export default class Confirmation extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            type: props.type,
            message: props.message,
        };
    }

    render() {
        const {
            type,
            message,
        } = this.state;

        return(
            <div id="Confirmation">
                {(type === "confirm" && message !== "") &&
                    <div>Your payment has been confirmed:<br />Confirmation #: {message}</div>
                }
                {(type !== "confirm" || message === "") &&
                    <div>Your payment failed to process<br/>{message}</div>
                }
            </div>
        );
    }
}